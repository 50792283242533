@import "src/scss/custom/variableCustom";

.BodyParentsLife {
  .picture {
    background-size: cover;
    background-position: center;
  }

  h1 {
    font-weight: 700;
    font-size: 48px !important;
    line-height: 56.25px;
    color: $blueAzure800;
  }

  .picture-one {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/maman-enceinte.png);
  }

  .picture-two {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/maman-bebe-mains.png);
  }

  .picture-three {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fillette-champ.png);
  }

  .picture-four {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fillette-blonde.png);
  }

  .picture-five {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/jeunes-escalier.png);
  }

  .picture-six {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/ado-bonnet.png);
  }

  .small-card {
    height: 132px !important;
  }

  .center {
    text-align: center;
    margin: -15px;
  }
}
