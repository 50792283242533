@import "./src/scss/custom/variableCustom";

.MyProfile {
  .rounded {
    border-radius: 50px !important;
  }
  label, p{
    color: $grey600 !important;
  }
  p {
    font-size: 12px;
  }
}
