@import "src/scss/custom/variableCustom";
@import "src/scss/custom/mixinCustom";

.ButtonComponent {
  .btnBleuAzure400 {
    @include simpleBtn($blueAzure400)
  }

  .btnBlueLight {
    @include simpleBtn($blue200)
  }

  .btnBlueDark {
    @include simpleBtn($blue700);
    border-color: white;
  }

  .btnGreenLight {
    @include simpleBtn($cyan100);
  }

  .btnGreen {
    @include simpleBtn($cyan100);
  }

  .btnBlueDarkWithoutBorder {
    @include simpleBtn($blue700);
  }

  .btnWhiteBorder {
    @include simpleBtn(rgba(255, 255, 255, 0));
    border: white 4px solid;
  }

  .btnWBBlue {
    @include btnWithoutBackground($blue200);
  }

  .btnBlueAzure400 {
    @include simpleBtn($blueAzure400)
  }
}
