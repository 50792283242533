@import "./src/scss/custom/variableCustom";

.BasicCard {
  cursor: pointer;

  .heart {
    color: $blue200;
  }

  .widget-services {
    height: 280px;
    width: 100%;

    .card-body {
      padding: unset;
    }

    .text {
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: rgba(128, 128, 128, 0.8);
      color: white;
      font-weight: bold;
      font-size: 0.9rem;

      .description {
        font-size: 0.8rem !important;
        font-weight: lighter !important;
        margin-bottom: 0;
      }
    }
  }

  .text.bigSpace {
    padding: 60px;
  }

  .text.smallSpace {
    padding: 30px;
  }
}
