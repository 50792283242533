@import 'src/scss/custom/_variableCustom.scss';
@import '~bootstrap';

.Register{

  //@include media-breakpoint-up(md) {
  //  position: absolute;
  //  top: 0;
  //  margin-top: -15vh;
  //}

  div.hide {
    display: none;
  }

  .goBack{
    color: $blue200;
    cursor: pointer;
  }

  h3.categoryTitle {
    font-size: 0.85rem !important;
    font-weight: bold !important;
    width: 100% !important;
  }

  label.categoryText {
    font-size: 0.8rem !important;
  }

  label, p{
    color: $grey600 !important;
  }

  .page-title {
    font-size: 2.375rem;
    font-weight: 500;
    color: $blue700;
    line-height: 1.07;
  }

  .required:after {
    content:" *";
  }
  .requiredPassword:after {
    content:" **";
  }

  .rounded {
    border-radius: 50px !important;
  }

  .text-break-space {
    white-space: break-spaces;
  }

  .titleInterest {
    color: $blueAzure300;
  }

  .underTitle {
    font-size: 14px !important;
    font-weight: 700;
  }
}