@import "../../../scss/custom/variableCustom";

.HomePage {
  .picture {
    background-size: cover;
    background-position: center;
  }

  h1 {
    font-weight: 700;
    font-size: 48px !important;
    line-height: 56.25px;
    color: $blueAzure800;
  }

  .picture-one {
    background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/photo_1.jpg");
  }

  .picture-two {
    background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/photo_2.jpg");
  }

  .picture-three {
    background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/photo_3.jpg");
  }

  .picture-four {
    background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/photo_4.jpg");
  }
}
