@import "src/scss/custom/variableCustom";

.CoachingParentsZensPlus {
  color: $grey600;
  font-size: 22px;

  .BlockGreen {
    background-color: $lightGreen800;
  }

  .subtitleOther {
    font-size: 36px !important;
  }
}
