@import "src/scss/custom/variableCustom";

.Replay {
  h2.workshop {
    color: $blue700 !important;
  }

  .imgSize {
    width: 100%;
  }

  .partVideoTitle {
    font-size: 1.2rem;
    color: $lightGreen800;
  }

  .partVideoTitle.workshop {
    font-size: 1.2rem;
    color: $blue700 !important;
  }

  .timeVideo {
    color: $blue200 !important;
  }
}
