@import "./src/scss/custom/variableCustom";

.heart {
  font-size: 2rem;
  cursor: pointer;
  color: $blueAzure400;
  align-items: center;
}

.like {
  font-size: 1rem;
  color: $blueAzure400;
  font-weight: bold;
}
