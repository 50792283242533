@import "./src/scss/custom/variableCustom";

.LegalInformation {
  font-size: 0.9em;
  margin-top: 3rem;

  * {
    color: $grey700 !important;
  }

  h1.page-subtitle {
    color: $blue200 !important;
  }
}