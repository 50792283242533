@import "~bootstrap/scss/bootstrap";
@import "./src/scss/custom/variableCustom";

.CardsTimeline {
  border: 1px solid $cyan100;
  border-bottom: 10px solid $cyan100;
  background-color: white;
  max-width: 250px;
  min-width: 100px;
  cursor: pointer;
  margin-left: -60%;
  @include media-breakpoint-only('xs') {
    margin-left: unset;
    width: 250px;
  }

  img {
    width: 100%;
    max-height: 130px;
    object-fit: cover;
  }

  p {
    font-weight: bold;
    font-size: 22px;
    color: $blueAzure400;
    height: 110px;
    width: 100%;
  }
}
