@mixin simpleTitleH2($color) {
  font-size: 1.70rem;
font-weight: bold !important;
color: $color;
}

@mixin h1Blue($color){
    font-weight: 700;
    font-size: 48px !important;
    line-height: 56.25px;
    width: 100%;
    text-align: center !important;
    color: $color !important;
}

@mixin h2Green($color){
  font-size: 1.56rem;
  font-weight: bold;
  text-align: center !important;
  color: $color !important;
}

@mixin h3LittleGrey($color) {
  font-size: 1rem !important;
  font-weight: bold !important;
  color: $color !important;
  width: 100% !important;
}

@mixin globalButton($color, $font-size, $font-weight, $width, $text-align, $line-height) {
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
  width: $width;
  text-align: $text-align;
  line-height: $line-height;
}

@mixin h3LittleGrey($color, $fontSize: 1rem){
    font-size: $fontSize !important;
    font-weight: bold !important;
    color: $color !important;
    width: 100% !important;
}

@mixin globalButton($color, $font-size, $font-weight, $width, $text-align, $line-height){
  color: $color;
  font-size: $font-size;
  font-weight: $font-weight;
  width: $width;
  text-align: $text-align;
  line-height: $line-height;
}

