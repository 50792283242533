@import "./variableCustom";

.menu-open {
  .fa-angle-left:before {
    content: "\f107";
  }
}

.content-wrapper, .main-header, .main-footer {
  margin-left: 275px !important;
}

body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) {
  .content-wrapper,
  .main-footer,
  .main-header {
    &,
    &::before {
      margin-left: 0 !important;
    }
  }
}


/* Home */
.picture {
  background-size: cover;
  background-position: center;
}

.banner-classic-background {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/maman-bebe-mains.png");
}

.widget-services {
  height: 300px;
  width: 100%;

  .card-body {
    padding: unset;
  }

  .text {
    position: absolute;
    bottom: 0;
    padding: 10px;
    width: 100%;
    background-color: #219E21;
    color: white;
    font-weight: bold;
  }
}

.line {
  border-bottom: 5px solid $grey200;
}

@media (max-width: 576px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}

@media (max-width: 992px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 993px ) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

.maman-enceinte {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/maman-enceinte.png);
}

.maman-bebe-mains {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/maman-bebe-mains.png);
}

.fillette-champ {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fillette-champ.png);
}

.fillette-blonde {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fillette-blonde.png);
}

.h-3 {

}

@media (max-width: 576px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.25rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}

$sizes: (1, $font-size-base * 2.5),
       (2, $font-size-base * 2),
       (3, $font-size-base * 1.75),
       (4, $font-size-base * 1.5),
       (5, $font-size-base * 1.25),
       (6, $font-size-base * 1),
       (7, $font-size-base * 0.75),
       (8, $font-size-base * 0.5);

@each $id, $size in $sizes {
  .fs-#{$id} {
    font-size: #{$size} !important;
  }
}

a.no-decoration, a.no-decoration:hover {
  color: unset;
}
.nav-treeview {
  li.active {
    background-color: $grey200;
    font-weight: bold;
  }
 }
$sizes: (1, $font-size-base * 2.5),
       (2, $font-size-base * 2),
       (3, $font-size-base * 1.75),
       (4, $font-size-base * 1.5),
       (5, $font-size-base * 1.25),
       (6, $font-size-base * 1),
       (7, $font-size-base * 0.75),
       (8, $font-size-base * 0.5);

@each $id, $size in $sizes {
  .fs-#{$id} {
    font-size: #{$size} !important;
  }
}

.greenText {
  p {
    color: $lightGreen800;
  }

  .warning {
    color: $orange600 !important;
  }
}