@import "./src/scss/custom/variableCustom";

.SearchResults {
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: white;
  }

  .MuiIconButton-colorPrimary {
    color: white;
  }

  .category {
    .heading {
      .MuiFormControlLabel-label::after {
        content: '+'
      }

      .MuiTypography-body1 {
        border-bottom: 2px solid $blue100;
      }
    }

    #activity.active {
      .MuiFormControlLabel-label::after {
        content: '-'
      }
    }

    .content {
      display: none !important;
    }

    .content.active {
      display: flex !important;
    }
  }

  .firstBlock {
    background-color: $blue200;
    color: white;

    .search {
      color: $grey600;
      background-color: white;
      border: solid $grey200 3px;
      border-radius: 5px;
      align-items: center;
      justify-content: center;

      input {
        width: 90%;
        border: none;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        margin-block-end: unset;
        color: $grey600;
      }

      input:focus {
        border: 0px solid transparent !important;
        outline: none;
      }

      p {
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        margin-block-end: unset;
        color: $grey600;
      }
    }
  }

  .secondBlock {
    border-radius: 30px;
    color: $grey600;
    background-color: $grey200;

    .title, .link {
      color: $blue200;
      font-weight: bold;
    }

    .link::after {
      content: attr(data-end);
      color: $blue700;
    }
  }

  .pagination {
    .MuiSvgIcon-root.MuiPaginationItem-icon {
      color: $blue700;
    }

    .MuiPaginationItem-page.Mui-selected {
      background-color: $blue200;
      color: white;
      font-weight: bold;
    }
  }
}
