@import "./src/scss/custom/variableCustom";

.SubMenu {
  .active {
    background: $cyan100 !important;
    color: white !important;

    &:hover {
      border: none !important;
    }
  }

  .nav-link {
    color: $grey600;
    font-weight: bold;
  }

  .nav-link.active {
    border-color: $cyan100 !important;
  }

  .nav-tabs {
    border-bottom: 7px solid $cyan100;
  }
}
