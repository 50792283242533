@mixin smallCard($background) {
  background-color: $background;
  padding: 2rem;
  color: white;
  text-align: center;
}

@mixin subtitle($color) {
  font-weight: bold;
  font-size: 24px;
  color: $color;
}
