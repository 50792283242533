@import "../../../scss/custom/variableCustom";
@import "../../../scss/custom/mixinsCustom";

.Gazouyi {
  h1 {
    font-weight: 700;
    font-size: 48px !important;
    line-height: 56.25px;
    color: $blueAzure800;
  }

  .round-corner-25 {
    border-radius: 25px !important;
  }

  .gazouyi {
    width: 100%;
    max-width: 250px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .btn-fat-green-800 {
    text-transform: uppercase;
    @include simpleBigBtn($lightGreen800)
  }

  .btn-green-800 {
    text-transform: uppercase;
    @include simpleBtn($lightGreen800);
  }

  .littleCondition {
    width: 100%;
    font-size: 12px;
    font-weight: bold;
  }

  .mb-80 {
    margin-bottom: 80px;
  }
}
