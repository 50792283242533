.abeille {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-abeille.jpg);
}

.abeille-manger {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_une_abeille_a_manger_image.jpg);
}

.activite-but {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_but_image.png);
}

.activités-vacances {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activites_pour_les_vacances.jpg);
}

.ado_ecran {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/ado_ecran.jpg);
}

.alan-de-la-cruz {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/alan-de-la-cruz-CmO_GydmKaY-unsplash.jpg);
}

.ame-artiste {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_une_ame_d_artiste_image.jpg);
}

.amr-serag {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/amr-serag-7GLsZZ0qBIk-unsplash.jpg);
}

.animaux-banquise {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-animaux-banquise.jpg);
}

.anthony-tran {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/anthony-tran-vXymirxr5ac-unsplash.jpg);
}

.anxiete-petit {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/anxiete_petit.jpg);
}

.apprendre-dessiner-animaux {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/apprendre_a_dessiner_des_animaux.jpg);
}

.apprentissage-jeu {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/apprentissage_jeu.jpg);
}

.arbre {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/arbre.png);
}

.arbre-empreinte {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_l_arbre_empreintes_image.jpg);
}

.arbre-genealogique {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_l_arbre_genealogique_image.jpg);
}

.arc-en-ciel {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_rideau_arc_en_ciel_image.jpg);
}

.architecte-interieur {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_architecte_d_inteerieur_image.jpg);
}

.art-therapie {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_art_therapie_image.jpg);
}

.atelier-gout {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_l_atelier_du_gouut_image.jpg);
}

.attrape-ballon {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_attrape_ballon_image.jpg);
}

.attrape-reve {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/tuto_attrape-reve.jpg);
}

.au-galop {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_au_pas_au_trot_au_galop_image.jpg);
}

.avions-papier {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/origami_des_avions_en_papiers.jpg);
}

.bac-a-sable {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mon_bac_a_sable_image.jpg);
}

.bain-poupee {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_bain_des_poupees_image.jpg);
}

.ballon-air {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_ballon_dans_les_airs_image.jpg);
}

.bateau {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_bateau_sur_l_eau_image.jpg);
}

.bateau-sur-l-eau {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_bateau_sur_l_eau_image.jpg);
}

.banniere-nos-partenaires-selectionnes {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/banniere-nos-partenaires-selectionnes.png);
}

.batterie-cuisine {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_une_batterie_de_cuisine_image.jpg);
}

.biodiversite-abeille {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-abeille.jpg);
}

.biodiversite-animaux-banquise {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-animaux-banquise.jpg);
}

.biodiversite-animaux-de-la-jungle {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-animaux-de-la-jungle.jpg);
}

.biodiversite-animaux-de-la-mer {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-animaux-de-la-mer.jpg);
}

.biodiversite-coloriage-magique-animal-de-la-forêt {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-coloriage-magique-animal-de-la-forêt.jpg);
}

.biodiversite-insectes {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-insectes.jpg);
}

.biodiversite-insectes {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-insectes.jpg);
}

.biodiversite-oiseau {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/biodiversite-oiseaux.jpg);
}

.boite-a-emotion {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_boite_a_emotions_image.jpg);
}

.boite-a-secret {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_boite_a_secret_image.jpg);
}

.boite-magique {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_boite_magique_image.jpg);
}

.boite-mystere {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_boite_mystere_image.jpg);
}

.bon-1er-mai {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Bon-1er-mai.jpg);
}

.bete-qui-monte {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_petite_bete_qui_monte_qui_monte_image.jpeg");
}

.boites-surprise {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_les_boites_surprise_image.jpg);
}

.boule-colere {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_boule_de_la_colere_image.jpg);
}

.bouquet-du-jour {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_bouquet_du_jour_image.jpg);
}

.bouquet-mouchoirs {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_un_bouquet_de_mouchoirs_image.jpg);
}

.bowling {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_bowling_image.jpg);
}

.bricolage-jardinage {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/bricolages_de_jardinage_a_faire_avec_les_enfants.jpg);
}

.brin-muguet {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_dessine_moi_un_brin_de_muguet_image.jpg);
}

.brin-de-muguet {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Brin-de-muguet.gif);
}

.bulle-de-savon-geantes {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_bulles_de_savon_geantes_image.jpg);
}

.cache-cache {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_cache_cache_image.jpeg);
}

.cadre-attachant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_un_cadre_attachant_image.jpg);
}

.cahier-pixel {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/un_cahier_de_pixel_art_gratuit.jpg);
}

.carte-a-gratter {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_carte_a_gratter_image.png);
}

.carte-de-printemps {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/carte_de_printemps.gif);
}

.carte-empreinte-de-paques {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_ma_carte_empreinte_de_Paques_image.png);
}

.carte-gazette-fete-des-peres {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/carte_gazette_fete_des_peres.jpg);
}

.carte-nounours {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/carte_nounours.jpg);
}

.carte-super-hero-fete-des-peres {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/carte_super_heros_fete_des_peres.jpg);
}

.carton-textures {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_carton_des_textures_image.jpeg");
}

.cerceau {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite__le_cerceau_des_decouvertes_image.jpeg);
}

.chamboule-tout {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_chamboule_tout_image.jpg);
}

.charge_mentale {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/charge_mentale.jpg);
}

.chasse-au-tresor {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_chasse_au_tresor_image.png);
}

.chasse-tresor {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_chasse_au_tresor_image.jpg);
}

.chaud-et-froid {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_chaud_et_froid_image.jpeg");
}

.chaussettes {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_je_trie_mes_chaussettes_image.jpg);
}

.chef-grand {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/petit_chef_deviendra_grand.jpg);
}

.circuit-bille-carton {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_circuit_de_bille_en_carton_image.jpg);
}

.cloches {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/cloches.jpg);
}

.cloche-et-oeufs {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/cloche-et-oeufs.jpg);
}

.cmonetiquette {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/cmonetiquette.png);
}

.coachingParents {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/femme_plage.png);
}

.coccinelle {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coccinelle.png);
}

.coiffeur {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_chez_le_coiffeur_image.png);
}

.coloriage {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage.jpg);
}

.coloriage-a-personnaliser {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_a_personnaliser.jpg);
}

.coloriage-coeurs {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_coeurs.jpg);
}

.coloriage-colere {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_colere.jpg);
}

.coloriage-gateau-point-a-point {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_gateau_point_a_point.jpg);
}

.coloriage-joie-winny {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_joie_winny.png);
}

.coloriage-magique {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_magique.gif);
}

.coloriage-magique-abeille {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Coloriage-magique-abeille.png);
}

.coloriage-magique-anniversaire {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Coloriage_magique_anniversaire.jpg);
}

.coloriage-magique-emotions {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_magique_emotions.jpg);
}

.coloriage-magique-fleur {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_magique_fleur.gif);
}

.coloriage-magique-de-paques {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage-magique-de-paques.jpg);
}

.coloriage-magique-de-paques-2 {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage-magique-de-Paques-2.jpg);
}

.coloriage-monsieur-perreux {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_monsieur_perreux.png);
}

.coloriage-moustaches {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_moustaches.jpg);
}

.coloriage-papa {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_papa.jpg);
}

.coloriage-personnalites {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriages_personnalites.jpg);
}

.coloriage-ratatouille {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage-ratatouille.jpg);
}

.coloriage-tristesse {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage_tristesse.jpg);
}

.coloriage-zootopie {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coloriage-zootopie.jpg);
}

.confection-pompons {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_confection_de_pompons_image.jpg);
}

.confiance-en-soi {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/confiance_en_soi.jpg);
}

.conseils-manger-bouger {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/conseils_manger_bouger_pour_les_parents.jpg);
}

.construire-pour-mieux-demolir {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_construire_pour_mieux_demolir_image.jpeg");
}

.costume-fete-des-peres {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/carte_costume_fete_des_peres.jpg);
}

.couleurs-et-formes {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_couleurs_et_formes_image.png);
}

.couronne-laurier {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_couronne_de_laurier_image.jpg);
}

.course-a-l-oeuf {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_course_a_l_oeuf_image.jpg);
}

.course-en-sac {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_course_en_sac_image.jpg);
}

.craie-coquille {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_craie_aux_coquilles_d_oeufs_image.jpg);
}

.creche-pas-ecole {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/la_creche_nest_pas_lecole.jpg);
}

.cube-fete-des-peres {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/cube_fete_des_peres.jpg);
}

.cuisine-famille {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/cuisiner_en_famille.jpg);
}

.cuisiner-jeu-enfant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/cuisiner_un_jeu_d_enfant.jpg);
}

.cupcakes-animaux {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_cupcakes_animaux_image.jpg);
}

.corryne-wooten {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/corryne-wooten-NYws9w9mhZk-unsplash.jpg);
}

.dames-creches {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/les_dames_de_la_creche.jpg);
}

.danse-journal {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_danse_du_papier_journal_image.jpg);
}

.de-des-emotions {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_de_des_emotions_image.jpg);
}

.des-bulles {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_des_bulles_image.jpeg");
}

.des-labyrinthes {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_labyrinthes.jpg");
}

.dessin-de-moi {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mon_dessin_de_moi_image.png);
}

.dessine-moi-histoire {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/dessine_moi_une_histoire.jpg);
}

.dessin-lasser {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_dessin_a_lasser_image.jpg);
}

.development-language {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/developpement_language.jpg);
}

.diplome-du-super-papa {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/diplome_du_super_papa.jpg);
}

.docteur {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/docteur.jpg);
}

.dragon-et-gateau {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/dragon_et_gateau.png);
}

.drapeau-europeen {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_drapeau_europeen_image.jpg);
}

.ecole-loisir {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/l_ecole_des_loisirs.jpg);
}

.ecuries {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_les_ecuries_d_augias_image.jpg);
}

.ecuries-d-augias {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_les_ecuries_d_augias_image.jpg);
}

.empilons-les-pates {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_empilons_les_pates_image.png);
}

.empreinte {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mon_empreinte_souvenir_image.jpg);
}

.enfant-dys {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/enfant_dys.jpg);
}

.enfant-meditation {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/enfant_meditation.jpg);
}

.enfilons-nature {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_enfilons_la_nature_image.png);
}

.enigmes-tous-genres {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_enigmes_en_tous_genres_et_pour_tous_les_ages.jpg);
}

.enlever-scotch {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_enlever_le_scotch_image.jpg);
}

.equilibre {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_jeu_d_equilibre_image.png);
}

.equilibriste {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_l_equilibriste_image.jpg);
}

.eveil-petit {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/20_activites_d_eveil_pour_les_tout_petits.jpg);
}

.explorez_oceans {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/explorez_les_oceans.jpg);
}

.fete-des-peres-mandala {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fete_des_peres_mandala.jpg);
}

.fille-garcon {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/fille_garcon.jpg);
}

.fleurs {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Fleurs.png);
}

.fonds-marins {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fonds-marins.gif);
}

.foret-tropicale {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/foret-tropicale.gif);
}

.gateau-anniversaire-3ans {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Gateau_anniversaire_3ans.jpg);
}

.gazouyi-img {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/gazouyi2.png);
}

.girafes {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/girafes.jpg);
}

.greli-grolot {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_greli_grolot_image.jpg);
}

.gymnastique {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_gymnastique_rythmique_image.jpg);
}

.guirlande-de-paques {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_guirlande_de_paques_image.png);
}

.guirlande {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_guirlande_en_farandole_image.jpg);
}

.hal-gatewood {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/hal-gatewood-AzDHa9F9uBY-unsplash.jpg);
}

.hepar {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/hepar_kv_6_hd.png);
}

.hercule {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/hercule.jpg);
}

.histoire-du-bout-des-doigts {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_une_histoire_du_bout_des_doigts_image.jpg);
}

.idee-diy-jardinage {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/idees_de_diy_de_jardinage_a_faire_avec_les_enfants.jpg);
}

.idées-jardinage-enfant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/15_idees_de_jardinage_avec_les_enfants.jpg);
}

.imagier {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_imagier_image.jpg);
}

.implication-peres {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/implication_peres.jpg);
}

.infection-pneumo-orl{
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/Infections-pneumo-orl-les plus-frequentes.jpg");
}

.infirmiere {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Infirmiere.png);
}

.isaac-del-toro {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/isaac-del-toro-b1NsVYklP6g-unsplash.jpg);

}

.jacques {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_jacques_a_dit_image.png);
}

.jardiner-enfant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/jardiner_avec_son_enfant.jpg);
}

.jeu-exterieur {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/jeu_exterieur.jpg);
}

.jeux-de-construction {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_je_trie_mes_jeux_de_construction_image.png);
}

.kaplas {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_une_chateau_de_kaplas_image.jpg);
}

.kevin-gent {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/kevin-gent-DIZBFTl7c-A-unsplash.jpg);
}

.la-colombe-de-la-paix {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la-colombe-de-la-paix.jpg);
}

.la-course-en-rouleaux {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_course_en_rouleaux_image.jpeg");
}

.l-ascenseur {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_l_ascenseur_image.jpeg");
}

.la-peur-coloriage-et-gommettes {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/La_peur_coloriage_et_gommettes.jpg);
}

.lampe-lave-maison {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/lampe_a_lave_maison.jpg);
}

.lapin {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/lapin.jpg);
}

.lapin-gourmand {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_lapin_gourmand_image.jpg);
}

.lapin-tampon {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_lapin_tampon_image.jpg);
}

.lance-sacs {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_lance_de_sacs_image.jpg);
}

.le-coq-francais {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le-coq-francais.jpg);
}

.le-drapeau-de-la-croix-rouge {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le-drapeau-de-la-croix-rouge.png);
}

.le-drapeau-europeen {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le-drapeau-europeen.jpg);
}

.le-drapeau-francais {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le-drapeau-francais.jpg);
}

.le-printemps {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_printemps.jpg);
}

.le-vent-se-leve {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_vent_se_leve_image.jpg");
}

.les-abeilles {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/les_abeilles.jpg);
}

.les-emoticones {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/les_emoticones.jpg);
}

.les-pieds-dans-le-riz {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_les_pieds_dans_le_riz_image.jpg");
}

.liberer-objet-1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_liberer_les_objets_1_image.jpg);
}

.liberer-objet-2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_liberer_les_objets_2_image.jpg);
}

.licorne-et-gateau {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Licorne_et_gateau.gif);
}

.limbo {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_limbo_image.jpg);
}

.livre-audio-mathilda {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/livres_audio_mathilda_de_roald_dahl.jpg);
}

.loto-sons {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_loto_des_sons_image.jpg);
}

.madame-bonheur {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/madame_bonheur.jpg);
}

.mai {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mai.jpg);
}

.mangeoire-rigolote {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_une_mangeoire_rigolote_image.jpg);
}

.manipulation {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_manipulations_de_graines_image.jpg);
}

.marche-animaux {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_marche_des_animaux_image.jpg);
}

.marque-page {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_marque_page_image.jpg);
}

.marque-page-robot {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_creation_d_un_marque_page_robot_image.jpg);
}

.massage-tout-doux {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_un_massage_tout_doux_image.jpg");
}

.mastermind {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mastermind_image.png);
}

.memory-animaux {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_memory_des_animaux_image.jpg);
}

.memory-emotion {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_memory_des_emotions_image.jpg);
}

.mon-corps {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mon_corps_image.jpg);
}

.monsieur-anniversaire {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Monsieur_anniversaire.jpg);
}

.mothersday-1000-merci {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_1000_merci.jpg);
}

.mothersday-bonne-fete-maman {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_bonne_fete_maman.jpg);
}

.mothersday-bonne-fete-maman-2 {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_bonne_fete_maman_2.jpg);
}

.mothersday-rosace-coeur {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_rosace_coeur.jpg);
}

.mothersday-train {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_train.jpg);
}

.mothersday-carte-avec-photo {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_carte_avec_photo.jpg);
}

.mothersday-carte-coeur-et-photo {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_carte_coeur_et_photo.jpg);
}

.mothersday-carte-coeur-et-poeme {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_carte_coeur_et_poeme.jpg);
}

.mothersday-carte-coeur {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_carte_coeur.jpg);
}

.mothersday-carte-margueritte {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_carte_margueritte.jpg);
}

.mothersday-carte-nounours {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_carte_nounours.jpg);
}

.mothersday-fleurs {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_fleurs.jpg);
}

.mothersday-diplome-meilleure-maman-du-monde {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/mothersday_diplome_meilleure_maman_du_monde.jpg);
}

.motricite-transvasement {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_motricite_fine_et_transvasement_image.png);
}

.muguet {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/muguet.jpeg);
}

.muguet-et-clochettes {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/Muguet-et-clochettes.jpg);
}

.muscles {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_travaillons_nos_muscles_image.jpg);
}

.musicien {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_musicien_image.jpg);
}

.musique-enfant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/musique_enfant.jpg);
}

.nuance-blanc {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_nuances_de_blanc_image.jpg);
}

.occuper_plusieurs_enfants {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/occuper_plusieurs_enfants.jpg);
}

.oeuf-et-lapin {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/oeuf-et-lapin.jpg);
}

.oiseau-pompon {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_un_oiseau_pompon_image.jpg);
}

.oiseaux {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/oiseaux.jpg);
}

.origamis-tous-genres {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/origamis_en_tous_genres.jpg);
}

.ours-polaire {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/ours-polaire.jpg);
}

.papillon-papier {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_papillon_en_papier_image.jpg);
}

.parcours-de-sante {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_parcours_de_sante_image.jpg);
}

.parcours-moteur {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_parcours_moteur_image.jpg);
}

.parcours-sante {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mon_parcours_de_sante_image.jpg);
}

.pate-a-modeler {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_pate_a_modeler_image.jpg);
}

.peche-aux-bouchons {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_a_la_peeche_aux_bouchons_image.jpg);
}

.peindre-cafe {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_peindre_avec_du_cafe_image.jpg);
}

.peinture-comestible {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_peinture_comestible_image.jpg);
}

.peinture-propre {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_peinture_propre_image.jpeg);
}

.peinture-seche {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_peinture_seche_image.jpg);
}

.pense-bete {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_pense_bete_image.jpg);
}

.periode-adaptation {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/la_periode_dadaptation.jpg);
}

.petit-au-plus-grand {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_du_plus_petit_au_plus_grand_image.jpg);
}

.petit-jardin {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mon_petit_jardin_image.jpg);
}

.petits-trous {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_des_petits_trous_image.png);
}

.photo-souvenir {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_une_photo_souvenir_image.jpg);
}

.photo_podcasts {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/photo_podcasts.png);
}

.photo_podcasts_sherazade1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/sherazade_chapitre_1.jpg);
}

.photo_podcasts_sherazade2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/sherazade_chapitre_2.jpg);
}

.photo_podcasts_sherazade3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/sherazade_chapitre_3.jpg);
}

.photo_podcasts_sherazade4 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/sherazade_chapitre_4.jpg);
}

.photo_podcasts_sherazade5 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/sherazade_chapitre_5.jpg);
}

.photo_podcasts_urashima1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/urashima_chapitre_1.jpg);
}

.photo_podcasts_urashima2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/urashima_chapitre_2.jpg);
}

.photo_podcasts_blancherose_roserouge1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/blanche-rose_et_rose-rouge_chapitre_1.jpg);
}

.photo_podcasts_blancherose_roserouge2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/blanche-rose_et_rose-rouge_chapitre_2.jpg);
}

.photo_podcasts_casse-noisette_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/casse-noisette_chapitre_1.jpg);
}

.photo_podcasts_casse-noisette_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/casse-noisette_chapitre_2.jpg);
}

.photo_podcasts_casse-noisette_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/casse-noisette_chapitre_3.jpg);
}

.photo_podcasts_casse-noisette_chapitre_4 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/casse-noisette_chapitre_4.jpg);
}

.photo_podcasts_casse-noisette_chapitre_5 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/casse-noisette_chapitre_5.jpg);
}

.photo_podcasts_comment_il_poussa_une_bosse_au_chameau {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/comment_il_poussa_une_bosse_au_chameau.jpg);
}

.photo_podcasts_coppelia_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coppelia_chapitre_1.jpg);
}

.photo_podcasts_coppelia_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coppelia_chapitre_2.jpg);
}

.photo_podcasts_coppelia_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coppelia_chapitre_3.jpg);
}

.photo_podcasts_coppelia_chapitre_4 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coppelia_chapitre_4.jpg);
}

.photo_podcasts_coppelia_chapitre_5 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/coppelia_chapitre_5.jpg);
}

.photo_podcasts_la_belle_au_bois_dormant_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_belle_au_bois_dormant_chapitre_1.jpg);
}

.photo_podcasts_la_belle_au_bois_dormant_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_belle_au_bois_dormant_chapitre_2.jpg);
}

.photo_podcasts_la_belle_au_bois_dormant_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_belle_au_bois_dormant_chapitre_3.jpg);
}

.photo_podcasts_la_belle_au_bois_dormant_chapitre_4 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_belle_au_bois_dormant_chapitre_4.jpg);
}

.photo_podcasts_la_belle_au_bois_dormant_chapitre_5 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_belle_au_bois_dormant_chapitre_5.jpg);
}

.photo_podcasts_la_chevre_de_monsieur_seguin_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_chevre_de_monsieur_seguin_chapitre_1.jpg);
}

.photo_podcasts_la_chevre_de_monsieur_seguin_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_chevre_de_monsieur_seguin_chapitre_2.jpg);
}

.photo_podcasts_la_grotte_des_korrigans_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_grotte_des_korrigans_chapitre_1.jpg);
}

.photo_podcasts_la_grotte_des_korrigans_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/la_grotte_des_korrigans_chapitre_2.jpg);
}

.photo_podcasts_le_baba-yaga-chapitre-1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_baba-yaga-chapitre-1.jpg);
}

.photo_podcasts_le_baba-yaga-chapitre-2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_baba-yaga-chapitre-2.jpg);
}

.photo_podcasts_le_briquet_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_briquet_chapitre_1.jpg);
}

.photo_podcasts_le_briquet_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_briquet_chapitre_3.jpg);
}

.photo_podcasts_le_briquet_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_briquet_chapitre_3.jpg);
}

.photo_podcasts_le_carnaval_des_animaux_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_carnaval_des_animaux_chapitre_1.jpg);
}

.photo_podcasts_le_carnaval_des_animaux_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_carnaval_des_animaux_chapitre_2.jpg);
}

.photo_podcasts_le_carnaval_des_animaux_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_carnaval_des_animaux_chapitre_3.jpg);
}

.photo_podcasts_le_carnaval_des_animaux_chapitre_4 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_carnaval_des_animaux_chapitre_4.jpg);
}

.photo_podcasts_le_carnaval_des_animaux_chapitre_5 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_carnaval_des_animaux_chapitre_5.jpg);
}

.photo_podcasts_le_chemin_du_diable {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_carnaval_des_animaux_chapitre_5.jpg);
}

.photo_podcasts_le_lac_des_cygnes_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_lac_des_cygnes_chapitre_1.jpg);
}

.photo_podcasts_le_lac_des_cygnes_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_lac_des_cygnes_chapitre_2.jpg);
}

.photo_podcasts_le_lac_des_cygnes_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_lac_des_cygnes_chapitre_3.jpg);
}

.photo_podcasts_le_lac_des_cygnes_chapitre_4 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_lac_des_cygnes_chapitre_4.jpg);
}

.photo_podcasts_le_lac_des_cygnes_chapitre_5 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_lac_des_cygnes_chapitre_5.jpg);
}

.photo_podcasts_le_lievre_l_hippopotame_et_l_elephant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_lievre_l_hippopotame_et_l_elephant.jpg);
}

.photo_podcasts_le_paysan_et_le_samourai_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_paysan_et_le_samourai_chapitre_1.jpg);
}

.photo_podcasts_le_paysan_et_le_samourai_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_paysan_et_le_samourai_chapitre_2.jpg);
}

.photo_podcasts_le_petit_poucet_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_petit_poucet_chapitre_1.jpg);
}

.photo_podcasts_le_petit_poucet_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_petit_poucet_chapitre_2.jpg);
}

.photo_podcasts_le_petit_poucet_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_petit_poucet_chapitre_3.jpg);
}

.photo_podcasts_le_troll_et_les_7_freres_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_troll_et_les_7_freres_chapitre_1.jpg);
}

.photo_podcasts_le_troll_et_les_7_freres_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_troll_et_les_7_freres_chapitre_2.jpg);
}

.photo_podcasts_le_petit_chaperon_rouge {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_petit_chaperon_rouge.jpg);
}

.photo_podcasts_les_deux_bossus_et_les_korrigans_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/les_deux_bossus_et_les_korrigans_chapitre_1.jpg);
}

.photo_podcasts_les_deux_bossus_et_les_korrigans_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/les_deux_bossus_et_les_korrigans_chapitre_2.jpg);
}

.photo_podcasts_les_musiciens_de_breme_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/les_musiciens_de_breme_chapitre_1.jpg);
}

.photo_podcasts_les_musiciens_de_breme_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/les_musiciens_de_breme_chapitre_2.jpg);
}

.photo_podcasts_l_ecole_des_loisirs {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/l_ecole_des_loisirs.jpg);
}

.photo_podcasts_l_oie_d_or_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/l_oie_d_or_chapitre_1.jpg);
}

.photo_podcasts_l_oie_d_or_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/l_oie_d_or_chapitre_2.jpg);
}

.photo_podcasts_l_oie_d_or_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/l_oie_d_or_chapitre_3.jpg);
}

.photo_podcasts_raiponce_chapitre_1 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/raiponce_chapitre_1.jpg);
}

.photo_podcasts_raiponce_chapitre_2 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/raiponce_chapitre_2.jpg);
}

.photo_podcasts_raiponce_chapitre_3 {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/raiponce_chapitre_3.jpg);
}

.plats-enfants {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_plats_a_preparer_avec_les_enfants.jpg);
}

.plats-faciles-enfants {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_plats_faciles_a_preparer_avec_des_enfants.jpg);
}

.plats-preferes-enfants {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/les_plats_preferes_des_enfants.jpg);
}

.point-a-point-lapin {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/point-a-point-lapin.jpg);
}

.pollution {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/pollution.jpg);
}

.pomme-api {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activites_pomme_d_api.jpg);
}

.porte-photo {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_porte_photo_image.jpg);
}

.porte-telephone {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_porte_telephone_image.jpg);
}

.pot-a-crayon {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_pot_a_crayons_image.jpg);
}

.potager-enfant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/le_potager_des_enfants.jpg);
}

.porte-bijoux {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_porte_bijoux_image.jpg);
}

.poule {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/poule.jpg);
}

.poule-decoree {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_poule_decoree_image.jpg);
}

.poussin {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/poussin.png);
}

.premiers-soins {
  background-image: url("https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/premiers-soins.jpg");
}

.promenade-imaginaires {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/promenades_imaginaires.jpg);
}

.promenade-nuages {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_promenade_dans_les_nuages_image.jpg);
}

.puzzle-historique {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_puzzle_historique_image.jpg);
}

.quelles-emotions {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_quelles_emotions_image.jpg);
}

.queue-leuleu {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_a_la_queue_leuleu_image.png);
}

.rashid-sadykov {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/rashid-sadykov-3NebKP7Zfvc-unsplash.jpg);
}

.rebus-imprimer {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_rebus_a_imprimer.jpg);
}

.recettes-masques-visage {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/10_recettes_de_masques_pour_visage_homemade.jpg);
}

.recettes-originales-enfants {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_recettes_originales_pour_les_enfants.jpg);
}

.recettes-plus-petits {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_recettes_pour_impliquer_les_plus_petits.jpg);
}

.recettes-rapides-faciles {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_recettes_rapides_et_faciles.jpg);
}

.recettes-sushis-faciles {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/recette_de_sushis_faciles.jpg);
}

.reflexions-games {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_messages_codes_des_rallyes_une_foule_de_jeux_de_reflexion.jpg);
}

.rentree {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/rentree.jpg);
}

.robo-wunderkind {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/robo-wunderkind-hLvQ4-QEBAE-unsplash.jpg);
}

.roi-mime {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_roi_du_mime_image.jpg);
}

.ryan-franco {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/ryan-franco-XECZHb6NoFo-unsplash.jpg);
}

.sac-mystere {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_sac_a_mysteres_image.jpg);
}

.sam-le-pompier {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/sam-le-pompier.jpg);
}

.sensorialite {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_sensorialite_image.png);
}

.sensoriel {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_parcours_sensoriel_image.jpeg);
}

.serpent-gommettes {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_mon_serpent_a_gommettes_image1.png);
}

.serviette {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_rond_de_serviette_image.jpg);
}

.sharon-mccutcheon {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/sharon-mccutcheon-eMP4sYPJ9x0-unsplash.jpg);
}

.sous-les-pieds {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_sous_les_pieds_image.jpg);
}

.suite-couleurs {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_suite_de_couleurs_image.jpg);
}

.suivi-enfant {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/suivi-de-sante-des-enfants.jpg);
}

.tapas-bacon {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/recette_les_tapas_de_bacon_tomates_et_fromage_fondu.jpg);
}

.taches-menageres {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/taches_menageres.jpg);
}

.charge-mentale-parle {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/Application_Myfamiliz.png);
}

.chayenne-rafaela {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/chayene-rafaela-nGwkIZFelko-unsplash.jpg);
}

.teletravail {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/articles/teletravail.jpg);
}

.tete-oeuf {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_tete_d_oeuf_image.jpg);
}

.tim-mossholder {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/tim-mossholder-H6eaxcGNQbU-unsplash.jpg);
}

.toboggan {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_le_tobbogan_a_noix_image.jpg);
}

.tombe {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_tombe_image.jpg);
}

.tour-lettre {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_la_tour_des_lettres_image.jpg);
}

.transvase {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_on_transvase_image.jpg);
}

.transvasement {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_transvasement_image.jpg);
}

.travaille-comme {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_je_travaille_comme_image.jpg);
}

.travaillons-muscles {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_travaillons_nos_muscles_image.jpg);
}

.tresor-enigmes {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/des_chasses_au_tresor_et_des_enigmes_pour_les_7-13_ans.jpg);
}

.tresors-tactiles {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_des_tresors_tactiles_image.jpg);
}

.tresse-africaine {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/tuto_tresse_africaine.jpg);
}

.trie-recupere {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_je_trie_je_recupere_image.jpg);
}

.trier-graines {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_trier_des_graines_image.jpg);
}

.tulipes-et-coccinelles {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/tulipes_et_coccinnelles.jpg);
}

.tuyau-a-balle {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_tuyau_a_balle_image.jpg);
}

.verne-ho {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/verne-ho-VIO0tyzXL4U-unsplash.jpg);
}

.vide-poches {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/activite_vide_poches_image.jpg);
}

.visite-virtuelle-sagrada {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/visite_virtuelle_de_la_sagrada_familia.jpg);
}

.vive-le-printemps {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/vive_le_printemps.jpg);
}

.xavier-mouton {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/xavier-mouton-photographie-MRWHSKimBJk-unsplash.jpg);
}

.yoga-children {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/15_postures_de_yoga_a_faire_avec_vos_enfants.jpg);
}

.yoga-house {
  background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/10_postures_de_yoga_a_faire_a_la_maison.jpg);
}
