@import "/src/appScss/imgtextcard";

.pageActivity {
  background-color: white !important;

  .nav-icon {
    font-size: 2rem;
    align-self: center;
  }

  .page-title {
    font-size: 2.375rem;
    font-weight: 500;
    color: $blue200;
    line-height: 1.07;
  }

  @media (max-width: 1025px) {
    .page-title {
      font-size: 1.875rem;
    }
  }

  .page-title .right {
    font-size: 0.875rem;
    font-weight: normal;
  }

  @media (min-width: 1025px) {
    .page-title .right {
      position: absolute;
      right: 5rem;
    }
  }

  .darkGreenTitle {
    color: $blueAzure400;
    font-weight: bold;
  }

  .img-card {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover;
  }

  .para-texts {
    background-color: $imgTextCardBack;
  }

  .subTitleActivity {
    color: $blueAzure400;
  }
}
