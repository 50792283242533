@import "~bootstrap/scss/bootstrap";
@import "./src/scss/custom/variableCustom";

.OriginalCard {
  border: 1px solid $grey200;
  border-bottom: 10px solid $cyan100;
  background-color: white;
  cursor: pointer;
  height: 21rem;

  img {
    width: 100%;
    height: 14rem;
    object-fit: cover;
  }
}
