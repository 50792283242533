// COLORS custom
// https://api.flutter.dev/flutter/material/Colors-class.html
// --------------------------------------------------------
$blue100: #9ed0ff;
$blue200: #3DA0FF !default;
$blue400: #2E61EB;
$blue500: #2D60EC;
$blue700: #2E00ED;
$blue800: #03388C;
$blue-blue900: #06037c;

$blueAzure300: #2E61EB;
$blueAzure400: #2D60EC;
$blueAzure800: #02378C;

$lightGreenAccent700: #5AD664;

$lightGreen800: #209C20;
$lightGreen900: #0E740E;

$grey100: #f0f0f0;
$grey200: #E6E6E6;
$grey600: #878787;
$grey700: #808080;
$grey800: #616161;

$jade200: #06CEC8;

$pink100: #ffcddb;
$pink200: #fe7878;

$orange600: #DB5E00;
$red500 : #FF0000FF;

$cyan100: #06CEC8;

//FONTS
$font-size-base: 1rem;

// height
$loginFotterHeight: 200px;
