@import '../../../scss/custom/variableCustom';
@import '~bootstrap/scss/bootstrap';

:root{
  --toastify-color-progress-success: #06CEC8;
}

.Login {

  h1 {
    font-size: 36px !important;
  }
  //@Todo trouver un meilleur moyen pour centrer le block Forgot password
  .bigPadding {
    padding-top: 6rem !important;
  }

  @media(min-width: 576px) {
    background-image: url(https://d3ua7f22ldad1o.cloudfront.net/heyparentszens/personnagesBig.png);
    background-position: 50% 60%;
    background-repeat: no-repeat;
    background-size: cover !important;
  }

  font-size: 0.9em;
  min-height: 100vh;
  @media (max-width: 376px) {
    .margin-mobile {
      margin-top: 80px;
    }
  }

  button {
    color: rgb(128, 128, 128);
    text-decoration: none;
    border: none;
    background-color: unset;
  }

  .linkLogin {
    color: $blue200;
  }

  .login-header
  {
    background-color: white;
    height: 6rem;
    width: 100%;

    .connexion {
      color: $blueAzure400;
      font-size: 24px;
    }

    .lineVertical {
      border-left: 1px solid $grey700;
      height: 3rem;
    }
  }

  .centerAccount {
    text-align-last: center !important;
  }

  .formLogin {
    min-height: 45rem;
    width: 80%;

    @media (min-height: 830px) and (min-width: 1000px) {
      padding: 4rem;
    }

    @media(min-width: 576px) {
      background-color: white;
      border: 1px solid #808080;
      border-radius: 15px;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      margin: 4rem 0;
      width: 50% !important;
    }
  }

  .mobile {

    .big-round-blue {
      background: $blue800;
      border-radius: 20rem;
      height: 43rem;
      margin-top: -25rem;
      position: relative;
      right: 10rem;
      width: 40rem;
      z-index: 20;
    }

    .round-logo {
      img {
        bottom: 0.5rem;
        left: -2.3rem;
        position: absolute;
      }

      background: white;
      border-radius: 5rem;
      height: 10rem;
      margin-left: 8rem !important;
      margin-top: -14rem !important;
      position: absolute;
      width: 10rem;
      z-index: 30;
    }

    .small-round-blue {
      background-color: $blueAzure300;
      border-radius: 15rem;
      height: 30rem;
      margin-left: 7rem;
      margin-top: -28rem;
      width: 30rem;
      z-index: 10;
    }
  }

  .container-fluid {
    color: $grey700 !important;
  }

  .formContainer {
    min-height: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    //margin-top: -$loginFotterHeight;

    h2.page-subtitle {
      text-align: left !important;
      color: $blueAzure300;
    }

    @include media-breakpoint-only(xs) {
      .page-subtitle {
        font-size: 35px !important;
      }

      h2.page-subtitle {
        font-size: 20px !important;
      }
    }
  }

  input.rounded {
    border-radius: 50px !important;
  }

  .login-footer {
    background-color: $grey200;
    padding: 1rem;
    //position: fixed;
    width: 100%;

    & a {
      color: $blueAzure300;
    }
  }

  .list-group-item {
    border: none;
    padding-left: 0;
  }

  .decal-login {
    margin-top: 20vh;
  }

  //@Todo faire un mixin pour éviter la répétition dans les autres classes
  .title {
    color: $cyan100 !important;
    font-family: 'Abril Fatface', sans-serif;
    font-size: 36px;
  }

  .underTitle {
    font-size: 14px !important;
    font-weight: 700;
  }

  @media(max-width: 576px) {
    .decal-login {
      margin-top: 3vh;
    }
  }

  @include media-breakpoint-up(md) {
    .decal-login {
      margin-top: unset;
    }
  }

  .btn {
    font-size: 14px !important;
  }
}
