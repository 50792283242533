@import "./src/scss/custom/variableCustom";

.Footer {
  font-size: 0.938rem !important;

  .nav-icon {
    color: $blueAzure400;
  }

  .round-blue {
    background-color: $blue800;
    border-radius: 7.5rem;
    height: 15rem;
    margin-bottom: -10rem;
    margin-left: 17rem;
    width: 15rem;

  }
}

@media(max-width: 576px) {
  .spacing-footer {
    margin-bottom: 0.5rem;
  }
}
