@import "./src/scss/custom/variableCustom";

.ActivityCard {
  height: 30rem;
  color: $grey600;
  box-shadow: $grey600;

  .title {
    font-weight: bold;
    font-size: 24px;
  }

  .imgHeight {
    height: 15rem;
  }

  .textHeight {
    height: 8rem;
  }
}
