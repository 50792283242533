@import "./src/scss/custom/variableCustom";
@import '~bootstrap/scss/bootstrap';

.ComplementaryGuard, .ComplementaryGuardO2, .ComplementaryGuardCera {
  @include media-breakpoint-up('sm') {
    .smallContainer {
      margin-right: 14rem;
      margin-left: 14rem;
    }
  }

  color: $grey600;

  @include media-breakpoint-only('xs') {
    .divImgLogo {
      width: 130px;
      height: 130px;
      margin: 0 auto;
    }
  }

  @include media-breakpoint-up('sm') {
    .divImgLogo {
      width: 150px;
      height: 150px;
    }
  }

  img {
    width: 100%;
    //height: 100%;
  }

  img.small {
    width: 70%;
  }

  .inputTextError {
    color: $red500;
  }

  .greenText {
    p {
      color: $lightGreen800;
    }

    .warning {
      color: $orange600 !important;
    }
  }

  .fa-pulse {
    font-size: 25px;
  }
}
