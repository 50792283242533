@import "appScss/imgtextcard";
@import "./scss/custom/variableCustom";

.App {
    text-align: center;
}

body {
    color: $grey600 !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    margin-top: 0;
    padding-top: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.content-wrapper {
    background-color: #ffffff !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.btn-blue {
    background: $blue200 !important;
    font-weight: bold !important;
    border-radius: 45px 45px 45px 45px !important;
    color: white !important;
    .center {
        text-align: center;
    }
}

.btn-green {
    background: $cyan100 !important;
    font-weight: bold !important;
    color: white !important;
    border: none;
    padding: 10px 45px 10px 45px !important;
    .center {
        text-align: center;
    }

    .button-center {
        justify-content: center;
    }
}
