.Search {
  input, :focus {
    outline: none !important;
    border-right: solid 1px grey !important;
    border-left: 0 !important;
    border-top: solid 1px grey !important;
    border-bottom: solid 1px grey !important;
    background-color: white !important;
    border-top-right-radius: 50px !important;
    border-bottom-right-radius: 50px !important;
  }

  @media (min-width: 795px) {
    input, input:focus {
      width: 20rem !important;
    }
  }

  .btn-navbar {
    border-left: solid 1px grey !important;
    border-top: solid 1px grey !important;
    border-bottom: solid 1px grey !important;
    background-color: white !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
  }
}