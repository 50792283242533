@import '~bootstrap';

.OurSelectedPartners {
  .center {
    text-align: center;
  }

  .MyFamiliz {
    img {
      width: 60% !important;
    }
  }

  img {
    width: 65% !important;
  }
  @include media-breakpoint-only('xs') {
    width: 100% !important;
  }
}
