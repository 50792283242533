@mixin simpleBtn($background) {
  background: $background !important;
  font-weight: bold !important;
  border-radius: 45px 45px 45px 45px !important;
  color: white !important;
  .center {
    text-align: center;
  }
}

@mixin btnWithoutBackground($color) {
  font-weight: bold !important;
  border-radius: 45px 45px 45px 45px !important;
  color: $color !important;
  .center {
    text-align: center;
  }
}

@mixin simpleBigBtn($background) {
  background: $background !important;
  font-weight: bold !important;
  color: white !important;
  border: none;
  padding: 10px 45px 10px 45px !important;
  .center {
    text-align: center;
  }

  .button-center {
    justify-content: center;
  }
}