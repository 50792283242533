.HeaderPage {
  .coachingParents {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/femme_plage.png);
  }

  .blondGirl {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/filleBlondeBle.png);
  }

  .parentsLife {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/crayons.png);
  }

  .picture {
    background-position: 25% 70% !important;
  }

  .picture.thirty {
    background-position: 25% 30% !important;
  }

  .picture.center {
    background-position: center !important;
  }

  .picture-banner {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/personnages.png);
  }

  .returnMaternityPaternityLeave {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/returnMaternityPaternityLeave2.png);
  }

  .picture-contactPage {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/image_page_contact.jpeg);
  }
}
