@import "./src/scss/custom/variableCustom";

.Aside {
  .active {
    background-color: $blueAzure400 !important;
    p {
      color: white !important;
    }
  }

  .brand-link {
    border-bottom: none !important;
  }

  .identity {
    color: $cyan100;
    font-family: 'Abril Fatface', sans-serif;
  }

  .limitBorder {
    border-bottom: 1px solid #dee2e6;
  }

  a, p {
    color: $grey700 !important;
  }
}

.NavBar {
  #openMenu {
    @media(min-width: 576px) {
      display: none;
    }
    font-size: 18px;
  }

  .disconnectUser {
    border-radius: 30px !important;
    width: 93px;
    background-color: $cyan100 !important;
    height: 31px;
    padding: 0 !important;
  }

  .marginBar{
    margin: 10px !important;
  }

  .sizeSvg {
    width: 75px !important;
  }
}
