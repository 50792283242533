@import "src/scss/custom/variableCustom";
@import "./allBackgroundImg";

.BodyParentsLife {
  .picture {
    background-size: cover;
    background-position: center;
  }

  h1 {
    font-weight: 700;
    font-size: 48px !important;
    line-height: 56.25px;
    color: $blueAzure800;
  }

  .maman-enceinte {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/maman-enceinte.png);
  }

  .maman-bebe-mains {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/maman-bebe-mains.png);
  }

  .fillette-champ {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fillette-champ.png);
  }

  .fillette-blonde {
    background-image: url(https://dwn7ag64m0dh8.cloudfront.net/heyparentszens/fillette-blonde.png);
  }

  svg {
    position: absolute;
    top: 1rem !important;
    right: 1rem;

    margin-top: unset;
  }

  .ThirdPanel {
    svg {
      position: unset !important;
      color: white;
    }

    .contentAudio {
      height: 16rem;
    }

    .play {
      width: 5rem;
      border: solid $blueAzure800;
      background-color: $blueAzure800;
      padding: 0.4rem;
      border-radius: 10px
    }
  }
}