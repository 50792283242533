@import "src/scss/custom/variableCustom";

.pageArticles {
  background-color: white !important;

  .nav-icon {
    font-size: 2rem;
    align-self: center;
  }

  .page-title-article {
    font-size: 2.375rem;
    font-weight: 500;
    color: $blueAzure800;
    line-height: 1.07;
  }

  @media (max-width: 1025px) {
    .page-title {
      font-size: 1.875rem;
    }
  }

  .page-title .right {
    font-size: 0.875rem;
    font-weight: normal;
  }

  @media (min-width: 1025px) {
    .page-title .right {
      position: absolute;
      right: 5rem;
    }
  }

  .img-card img {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover;
  }

  .center {
    text-align: center;
  }

  .header {
    overflow: hidden;
  }

  .baneer {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  .under{
    text-decoration: underline;
  }
  //Allows you to cut out words that extend beyond the div
  .break{
    word-break: break-word;
  }
}
