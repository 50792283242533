@import "src/scss/custom/variableCustom";

.ReturnMaternityPaternityLeave {
  .center {
    text-align: center;
  }

  .error {
    color: red !important;
  }

  .warning {
    line-height: normal;
    font-size: 15px;
  }


  color: $grey600;
  font-size: 16px;

  .content {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .greyBlock {
    background-color: $grey200;
    color: black;
    font-size: 16px !important;
    padding: 2rem;
    height: 100%;

    h2 {
      font-size: 24px;
      text-align: center !important;
    }

    ul {
      list-style-type: circle;

      li::marker {
        color: black;
      }
    }

    small {
      font-size: 70% !important;
    }

    label {
      font-weight: 100 !important;
    }
  }
}
