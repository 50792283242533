@import "src/scss/custom/variableCustom";

.ConferenceInscription {
  font-size: 21px;
  color: $grey600;

  .center {
    text-align: center;
  }

  .confirmMessage {
    color: $lightGreen800;
  }

  .error {
    color: red !important;
  }

  .warning {
    line-height: normal;
    font-size: 15px;
  }

  .waitingList {
    color: $orange600;
  }

  .spinner {
    text-align: center !important;
    width: 5rem;
    height: 5rem;
  }

  .blue200 {
    color: $blue200
  }

  .lineVertical {
    border-left: 3px solid $blue200;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  h2.workshop {
    color: $blue700 !important;
  }

  .content {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .participation {
    cursor: pointer;
    border: $lightGreen800 solid 2px;
  }

  .participation.workshop {
    cursor: pointer;
    border: $blue700 solid 2px;
  }

  .participation.active.workshop {
    border: $grey200 solid 1px !important;
    background-color: $blue700;
    color: white;
  }

  .participation.active {
    border: $grey200 solid 1px !important;
    background-color: $lightGreen800;
    color: white;
  }

  .imgSize {
    width: 100%;
  }

  .greyBlock {
    background-color: $grey200;
    color: black;
    font-size: 21px !important;
    padding: 2rem;
    height: 100%;

    h2 {
      font-size: 24px;
      text-align: center !important;
    }

    ul {
      list-style-type: circle;

      li::marker {
        color: black;
      }
    }

    small {
      font-size: 70% !important;
    }

    label {
      font-weight: 100 !important;
    }
  }
}
