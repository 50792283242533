.HeaderLogo {
  .header-picture {
    height: 230px !important;
  }

  .logoPlace {
    position: absolute;
    width: 428px;
    @media (max-width: 1025px) {
      width: 200px;
    }

    height: 250px;
    padding: 0 20px;
    top: 57px;
    right: 3rem;
    background: white;
    transform: skew(-20deg);
    justify-content: center;
    align-items: center;

    .img-logo {
      transform: skew(20deg);
      max-height: 90%;
      max-width: 90%;
    }

    .spinner {
      font-style: normal !important;
    }
  }
}