@import url("https://fonts.googleapis.com/css?family=Permanent+Marker|Rock+Salt");
@import "../../../../../../../scss/custom/variableCustom";

.App {
  font-family: sans-serif;
  text-align: center;
}

.player {
  justify-content: space-between;
  align-items: center;
  background-color: #212527;

  .song {
    user-select: none;
    margin: 0 20px;

    .song__title {
      text-align: left;
      margin: 0;
      color: white;
      font-family: "Permanent Marker", cursive;
      font-weight: normal;
      font-size: 3.5em;
    }

    .song__artist {
      margin: 0;
      color: white;
      font-family: "Rock Salt", cursive;
      font-weight: normal;
      font-size: 1em;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .player__button {
    width: fit-content;
    margin-bottom: 15px;
    background-color: transparent;
    border: none;
    margin-top: 6rem;

    &:focus {
      outline: none;
    }

    &:hover {
      cursor: pointer;
    }

    svg {
      font-size: 2em;
      color: white;
    }
  }

  .bar {
    margin-top: 5.2rem;

    .bar__time {
      color: $lightGreenAccent700;
      font-size: 16px;
    }

    .bar__progress {
      flex: 1;
      height: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}
