@import "./src/scss/custom/variableCustom";


.TimelineDesktop {
  /* General code */
  @import url(https://fonts.googleapis.com/css?family=Barlow+Condensed);

  body {
    font-family: "Barlow Condensed";
    margin: auto;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 16px;
  }

  button {
    width: 100px;
    margin: auto;
  }

  .timelineDesktop {
    display: flex;
    margin: auto;
    justify-content: center;
  }

  .timelineDesktop .circle {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: $grey700;
    margin: 0;
    padding: 2px;
    margin-left: -5px;
    z-index: 2;
    position: relative;
  }

  .timelineDesktop .circle:hover {
    cursor: pointer;
  }

  .timelineDesktop .circle p {
    text-align: center;
    margin: auto;
    line-height: 30px;
    color: #ffffff;
  }

  .timelineDesktop .link {
    z-index: 0;
    background-color: $grey700;
    opacity: 0.7;
    padding: 0;
  }

  .square {
    max-width: 250px;
    min-width: 100px;
    height: 250px;
    width: 250px;
  }

  .square.blank {
    visibility: hidden;
  }

  #end {
    width: 0;
    height: 0;
  }

  .square h3,
  .square h4 {
    margin: 0px 0px 0px 15px;
    text-align: left;
    font-size: 16px;
  }

  .square h3 {
    padding-top: 5px;
  }

  .square h4 {
    font-style: italic;
    font-weight: normal;
  }

  /* Linear timeline from left to right */
  #horizontal-timeline {
    flex-direction: row;
    min-width: 1100px;
  }

  .horizontal .link {
    width: auto;
    height: 5px;
    margin-top: -15px;
    margin-left: 5px;
  }

  .horizontal .square p {
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .triangle-code-up {
    height : 0;
    width : 0;
    border-right : 18px solid transparent;
    border-bottom : 36px solid $grey200;
    border-left : 18px solid transparent;
    margin-left: -8px;
  }

  .triangle-hidden {
    height : 0;
    width : 0;
    margin-bottom: 45px !important;
  }

  .triangle-code-down {
    height : 0;
    width : 0;
    border-right : 18px solid transparent;
    border-top : 36px solid $grey200;
    border-left : 18px solid transparent;
    margin-left: -8px;
  }

  .horizontal #end {
    margin: 302px 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid $grey700;
  }

  /* Linear timeline from top to bottom */
  .vertical .block {
    display: flex;
    flex-direction: row;
  }

  #vertical-timeline {
    flex-direction: column;
  }

  .vertical .link {
    width: 5px;
    height: 150px;
    margin-top: 0;
    margin-left: -20px;
  }

  .vertical .square {
    width: 250px;
    margin: 0px 25px 0px 35px;
  }

  .vertical .square p {
    padding: 0;
    margin: 0;
  }

  .vertical #end {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $grey700;
    margin: auto;
    margin-left: 311px;
  }

  .square::after {
    content: " ";
    display: block;
    z-index: 0;
    width: 0px;
    height: 0px;
  }

  .square.left::after {
    margin: -70px 11px 0px -15px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid rgb(176, 179, 204);
  }

  .square.right::after {
    margin: -75px 0px 0px 242px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid rgb(176, 179, 204);
  }
}
