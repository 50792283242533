@import "./src/scss/custom/variableCustom";

.ImgTextCard {
  .container .img-card {
    max-width: 100% !important;
    max-height: 100% !important;
    object-fit: cover;
  }

  .imgCard {
    h3{
      color: $lightGreen800;
    }

    .darkGreenTitle {
      color: $lightGreen800;
      font-weight: bold;
    }
    .row {
      display: flex;
      margin-right: -15px;
      margin-left: -15px;
    }

    .para-texts {
      background-color: #f0f0f0;
      padding: 3.5rem;
    }
  }

  .blocktext {
    margin-top: 3rem;
    margin-left: 3rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}
