@import "./src/scss/custom/variableCustom";

.SubMenu {
  .active {
    background: $blue200 !important;
    color: white !important;
    &:hover {
      border: none !important;
    }
  }
  .nav-link {
    color: black;
    font-weight: bold;
  }
  .nav-link.active {
    border-color: $blue200 !important;
  }

  .nav-tabs {
    border-bottom: 7px solid $blue200;
  }

}
