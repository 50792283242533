@import "./src/scss/custom/variableCustom";
@import "./src/scss/custom/mixinsCustom";


.ImgBlock {
  color: $grey600;

  .verySmallText {
    font-size: 13px !important;
  }

  .smallText {
    font-size: 14px !important;
  }

  img {
    height: 7rem;
    width: 100%;
    object-fit: cover;
  }

  .img {
    position: relative;
    p {
      position: absolute;
      right: 0;
      bottom: -1rem;
    }

    .subTitleGreen {
      @include subTitle($lightGreen800);
    }

    .subTitleBlue {
      @include subTitle($blue700);
    }
  }

  .childButton {
    position: absolute;
    bottom: 50px;
    width: 90%;
    height: 50px;
  }

  .content {
    position: relative;
    padding: 1rem 1rem 0 1rem;
    background-color: $grey100;
    text-align: center;
    height: 38rem;

    .date {
      text-align: left !important;
    }

    .description {
      font-style: italic;
    }

    .title {
      font-size: 22px;
      font-weight: bold;
    }
  }
}
