@import "./src/scss/custom/variableCustom";
@import "./src/scss/custom/mixinsCustom";

.Contact {
  @media (min-width: 576px){
    .buttonSend {
      float: right;
    }
  }

  .address, .contactInfo, .email, .phone {
    @media (max-width: 1200px){
      justify-content: center;
    }
  }

  .buttonSend, .contactText, .name, .smallSubtitle, .subTitle {
    @media (max-width: 1200px){
      text-align: center;
    }
  }

  .borderSide {
    border-left: #01a0e4 4px solid;
    border-right: #01a0e4 4px solid;
    padding-right: 3rem;
  }

  .buttonSend {
    @media (min-width: 1200px){
      float: right;
    }
  }

  .center {
    text-align: center;
  }

  .contactMessage {
    border: solid $grey200 1px;
    border-radius: 30px;
    padding: 0 1rem;
    width: 100%;
    &:focus, &:focus-visible {
      outline: none !important;
    }
    @media (max-width: 1200px){
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .fontAwesome {
    color: $cyan100;
  }

  .message {
    margin-bottom: 10rem;
    text-align: center !important;
    @media (min-width: 576px){
      text-align: left !important;
    }
  }

  .mycontent-left {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    width: 100%;
  }

  .name {
    color: $cyan100;
    font-size: 24px;
    font-family: 'Abril Fatface', 'Roboto', sans-serif;
  }

  .round-img {
    img {
      border: 6px solid $cyan100;
      border-radius: 50%;
      width: 9rem;
      height: 9rem;
    }
  }

  .smallSubtitle {
    color: $grey700;
    font-weight: bold;
  }


  .subTitle {
    @include subtitle($blue700)
  }

  .ws-pre {
    white-space: pre;
  }

  .name {
    color: $cyan100;
    font-weight: bold;
  }

  .smallSubtitle {
    color: $grey700;
    font-weight: bold;
  }

  .subTitle {
    @include subtitle($blue700)
  }
}
