@import "src/scss/custom/variableCustom";

.CoachingParentsZens {
  color: $grey600;
  font-size: 16px;

  .favoriteLabel.error::after {
    content: ' *';
  }

  .error {
    color: red !important;
  }

  .warning {
    line-height: normal;
    font-size: 15px;
  }

  .content {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .smallText {
    font-size: 11px;
  }

  @media (min-width: 564px) {
    .mlScreen {
      margin-left: 3rem !important;
    }

    h2 {
      font-size: 36px;
    }
  }

  @media (max-width: 563px) {
    .mlScreen {
      margin: 0 !important;
    }
  }

  .center {
    text-align: center;
  }


  h2 {
    font-size: 36px;
    text-align: center;
  }

  p {
    text-align: justify;
  }

  .rond {
    border: 2px solid black;
    border-radius: 50%;
    width: 15px;
    height: 15px;
  }

  .greyBlock {
    background-color: $grey100;
    color: black;
    font-size: 16px !important;
    padding: 2rem;
    height: 100%;

    h2 {
      font-size: 24px;
      text-align: center !important;
    }

    small {
      font-size: 70% !important;
    }

    label {
      font-weight: 100 !important;
    }

    .textarea-radius {
      textarea {
        padding: 1rem;
        border-radius: 40px;
        border: solid 1px black;
        width: 90%;
        height: 200px;
      }
    }
  }
}
