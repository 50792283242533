@import "./src/scss/custom/variableCustom";
@import "./src/scss/custom/mixinsCustom";

.NurseryPlace {
  .darkBlueCard {
    @include smallCard($blueAzure800);
  }

  .lightBlueCard {
    @include smallCard($blueAzure800);
  }

  .subTitle1 {
    @include subtitle($blueAzure400)
  }

  .subTitle2 {
    @include subtitle($cyan100)
  }

  .subTitle3 {
    @include subtitle($blue700);
  }

  .endInscription {
    font-size: 36px;
  }

  .round-img {
    width: 160px;
    height: 160px;
    align-items: center;

    img {
      height: 100%;
      border-radius: 50%;
      border: 5px solid $cyan100;
      width: 10rem;
    }
  }

  .cardTitle {
    color: $cyan100;
    font-weight: bold;
  }

  .img-resize {
    width: 100%;
    height: 90%;

    @media(max-width: 768px) {
      height: 80% !important;
    }
  }

  h3.blue {
    color: $blue700
  }

  dot {
    list-style-type: circle;
  }
}
