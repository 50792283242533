@import "./src/scss/custom/variableCustom";
@import "src/scss/custom/mixinCustom";

h1.blue {
  color: $blueAzure800 !important;
}

h1, h1.page-subtitle {
  @include h1Blue($blueAzure800)
}

h2, h2.page-subtitle {
  @include h2Green($blueAzure400)
}

h2.subtitleOther {
  font-size: 1.56rem;
  font-weight: bold;
  color: $blueAzure400;
}

h2.page-subtitle-green900 {
  @include simpleTitleH2($lightGreen900)
}

h2.smallTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: $lightGreen800;
}

h2.subtitleOtherDarkBlue {
  font-size: 1.56rem;
  font-weight: bold;
  color: $blueAzure400;
}

h2.page-subtitle-green900 {
  @include simpleTitleH2($lightGreen900)
}


h2.subtitleOtherDarkBlue {
  font-size: 1.56rem;
  font-weight: bold;
  color: $blueAzure400;
}

h2.white {
  font-size: 24px;
  font-weight: bold;
  color: white;
}

h2.blue {
  color: $blueAzure400 !important;
}

h3.page-subtitle-blue700 {
  @include simpleTitleH2($blue700)
}

h3.page-subtitle-blueAzure400 {
  @include simpleTitleH2($blueAzure400)
}

h3.page-subtitle-little-grey800 {
  @include h3LittleGrey($grey800)
}

h3.page-subtitle-middle-grey800 {
  @include h3LittleGrey($grey800, 2rem)
}

h3.page-subtitle-little-grey600 {
  @include h3LittleGrey($grey600)
}

h3.page-subtitle-little-grey600 {
  @include h3LittleGrey($grey600)
}

h3 {
  .size-title {
    font-size: 0.875rem;
    font-weight: bold;
    color: $grey800;
    width: 100%;
  }

  .size-title2 {
    font-size: 0.50rem !important;
    font-weight: bold !important;
    color: $grey800 !important;
    width: 100% !important;
  }

  h3 {
    .size-title {
      font-size: 0.875rem;
      font-weight: bold;
      color: $grey800;
      width: 100%;
    }

    .size-title2 {
      font-size: 0.50rem !important;
      font-weight: bold !important;
      color: $grey800 !important;
      width: 100% !important;
    }

    h3.page-subtitle-little-grey800 {
      @include h3LittleGrey($grey800)
    }

    h3.page-subtitle-little-grey600 {
      @include h3LittleGrey($grey600)
    }

    .size-title2 {
      font-size: 0.50rem !important;
      font-weight: bold !important;
      color: $grey800 !important;
      width: 100% !important;
    }

    .size {
      width: 100%;
      font-size: 20px;
      color: $grey800;
      text-align: left;
    }

    .H1Title {
      font-size: 3.50rem;
      text-align: left;
      margin-top: 50px;
      color: $blue200;
      font-weight: bold;
    }
  }

  h2.darkGreenTitle {
    color: $lightGreen800 !important;
  }

  h3.regularGreenTitle {
    color: $lightGreen800 !important;
  }
}

h3.regularGreenTitle {
  color: $lightGreen800 !important;
}